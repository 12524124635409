"use client";

import type { NextPage } from "next";
import dynamic from 'next/dynamic';
import LoadingComponent from '@common/loader/LoadingComponent';
import AppHeader from "@components/AppHeader";
import { SidebarContextProvider } from "@hooks/useSidebarContext";
import WizardFooter from "@hooks/wizard/WizardFooter";
import WizardSwitch from "@hooks/wizard/WizardSwitch";
import WizardContainer from "@components/WizardContainer";
import AppSidebar from "@components/AppSidebar";
import useLocalStorage from "@hooks/useLocalStorage";

// Ensure rendering on client so we don't get a weird state with localStorage
const Wizard = dynamic(() => import('@hooks/wizard/Wizard'), {
    loading: () => <LoadingComponent />,
    ssr: false,
});

const Page: NextPage = () => {
    const [formValue] = useLocalStorage<{ [key: string]: any }>("default");

    return (
        <div
            className="d-flex "
            style={{ height: "calc(var(--vh, 1vh) * 100)" }}
        >
            <SidebarContextProvider>
                <Wizard
                    id='default'
                    hasEIServerOrCloud={false}
                    hasEmergepay={false}
                    steps={[]}
                    values={formValue}
                >
                    <AppSidebar />
                    <div id="app-container" className="w-100 d-flex flex-column">
                        <AppHeader />
                        <div className="h-100 overflow-auto">
                            <WizardSwitch />
                            <WizardContainer />
                        </div>
                        <WizardFooter />
                    </div>
                </Wizard>
            </SidebarContextProvider>
        </div>
    );
};
export default Page;
